import React from "react";
import ReactDOM from 'react-dom/client';

import { createRoutes } from "clnt-common";

import routeList from './routeList';
import { siteData } from './data/siteData';
import App from './App';

function onRecoverableError(error, errInfo) {
 let context = {};
 
  if (errInfo?.componentStack) {
     // Generating this synthetic error allows monitoring services to apply sourcemaps
     // to unminify the stacktrace and make it readable.
     const errorBoundaryError = new Error(error.message);
     errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
     errorBoundaryError.stack = errInfo.componentStack;
 
     // REF: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
     error.cause = errorBoundaryError;
 
     // You can also just add the plain text as added event context.
     context.componentStack = errInfo.componentStack;
  }
 
  // Replace with your error monitoring service.
  //MyObservabilityPlatform.captureException(error, { context })
    console.error('entry-client:onRecoverableError() - error: ' + JSON.stringify(error)); 
    //console.log('entry-client:onRecoverableError() - context: ' + JSON.stringify(context)); 
    //console.log('entry-client:onRecoverableError() - errInfo: ' + JSON.stringify(errInfo)); 
}


// ssg: processed by node server; sets type to 'ssg' and wipes out data
// ssr: processed by node server; sets type to 'ssr' TODO
// spa: NOT processed by node server; type is not set;
const mount = (context, children) => {
  const root = document.getElementById('app');
  const type = context && context.type || 'spa';

  if (type === 'ssr' || type === 'ssg') {
    // SSR case
      ReactDOM.hydrateRoot(root, children, { onRecoverableError });
  }
  else {
    // SPA case
    ReactDOM.createRoot(root).render(children);
  }
}

(async () => {
  //
  // Setup client side routing
  // returns: { routes[{path:, element:,}], head, context }
  // Only routes is relevant on client side.
  // head/context are used on server side.
  //
  const { routes } = await createRoutes(routeList, window.location.pathname);

  // context comes from /src/function.js and it's placed in <script> tag by server side renderer
  // Which sets up the window.__context__ 
  // For SPA, the server side is not involved. NGNX serves the html and js which were build by vite
    
  // context = { type: , srvrData: , fetchData: , metaData: , allSrvrData: }    
  // srvrData = { slug: 'aaa', data: psqlData};
  // fetchData = { slug: 'aaa', data: psqlData};
  // metaData = { page: page, layout: '', advert: {}, head: [], meta: { type: 'tsxpage', } };
  // allSrvrData = { 'route1': route1Data, 'route2': route2Data, }
  
  let context = { };
  if (typeof window !== 'undefined' && typeof window.__context__ !== 'undefined') {
    context = window.__context__;
  }

  const currentRoute = window.location.pathname;    
  mount(context,
      <App type={'client'} appContext={context} routes={routes} route={currentRoute} />
  );
  
})();
/*
  Removed to prevent everything running twice!!!
  When used, the AppContext info msg on version appears twice
    <React.StrictMode>
    </React.StrictMode>
*/
