// We've removed clnt-common version of createEnvVar.
// If using from clnt-common, we can't get access to env. var via import.meta.env
// import { createEnvVar } from 'clnt-common';
//import { createEnvVar } from './createEnvVar.js';
//import keyValEnvVar from "./json/projEnvVar.json" with { type: "json" };
//import keyValEnvVar from "./json/projEnvVar.json?json";
import keyValEnvVar from "./json/projEnvVar.json" assert { type: "json" };

let isSetup = false;
let envMap = null;

// Capture URL params

//    jwt: getUrlParm('jwt'),
//    plan: getUrlParm('plan'),
//    searchTerm: getUrlParm('term') || '',
//    emertType: getUrlParm('type') || '', // options: 'recent' | 'search' | 'alerts'
//    displayMode: getUrlParm('display') || '', // options: 'table' | 'list' | 'slides'
/*
Use the code from clnt-common/util/util
function getUrlParm(name) {
  let value = null;
  if (
    typeof window !== 'undefined' &&
      window.location &&
      window.location.pathname //&&
    //(window.location.pathname.includes('/emerts') ||
    //  window.location.pathname.includes('/getstarted'))
  ) {
    // extract query parameters
    // http://localhost:8000/app/myemerts?display=list&type=search&term=sugar
    const searchStr = document.location.search.substring(1);
    if (searchStr) {
      value = new URLSearchParams(searchStr).get(name);
      value = (value && value.substr(0, 1250)) || ''; // For safety reqson we restrict parameter values to 1250 characters
    }
  }
  return value;
}
*/

function domainExtractor(hostname) {
  // assuming no port number is supplied as part of hostname
  // auth.emert.org, emert.org, localhost:8080, auth-emert-org.localhost, xxx.auth.emert.org:8000
  const hostnameParts = hostname.split('.').reverse();

  let domain = 'localhost';
  let subDomain = '';
  let tld = '';
  let domainTld = 'localhost';
  const ipRegEx = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
  const isIP = ipRegEx.exec(window.location.hostname); // isIP will be null if it's not IP

  if (hostnameParts.length > 1 && !isIP && hostnameParts[0] != 'localhost') {
    tld = hostnameParts[0];
    domain = hostnameParts[1];
    subDomain = hostnameParts[2] ? hostnameParts[2] : '';
    domainTld = domain + '.' + tld;
  }
  return { domain, subDomain, domainTld };
}

function makeQuery(urlParams) {
  let q = '';
  let first = true;
  for (var p in urlParams) {
    if (!first) {
      q = q + '&' + p + '=' + urlParams[p];
    } else {
      q = q + p + '=' + urlParams[p];
      first = false;
    }
  }
  return q;
}

const locationData = {
  domain: '',               //
  subDomain: '',            //
  pathname: '',

  urlQuery: null,           // Query string constructed from all urlParams 

  jwt: null,
  target: 'target?',        // Automatic return to application URL after Sign Up/Sign In
  nav: 'nav?',              // Which part of the left nav in AUTH, FORM, SPA app should be displayed on start
  app: 'app?',              // Refers to SPA app : lnch, auth, form, etc.,
  apptgt: 'apptgt?',        // Refers to user app : EMERT, CRUDLI, BITIGRAM, KRIPA, NULLIC, etc., matches to auth.emert.org / auth.crudli.com
  preset: {},               // Query parameter supplied preset values for the rendered form 
  accountNo: 'account?',    //  
  formNo: 'form?',          //
  //signin: 'no',             // Is Sign In required?
};

function updateLocationData(urlParams) {
  locationData.domain = urlParams.domain;
  locationData.subDomain = urlParams.subDomain;
  //locationData.hostname = urlParams.hostname;
  //locationData.protocol = urlParams.protocol;

  locationData.urlQuery = makeQuery(urlParams);

  if (urlParams.jwt) locationData.jwt = urlParams.jwt;
  if (urlParams.target) locationData.target = urlParams.target;
  if (urlParams.redirectUrl) locationData.target = urlParams.redirectUrl;   // Added to support Element/Synapse
  if (urlParams.nav) locationData.nav = urlParams.nav;
  if (urlParams.app) locationData.app = urlParams.app;
  if (urlParams.apptgt) locationData.apptgt = urlParams.apptgt;
  if (urlParams.pathname) locationData.pathname = urlParams.pathname;

  // Example: http://localhost:8019/?account=1449688635&form=1&apptgt=Recalls.Me&preset=%22{\%22Application\%22:\%22abcd\%22}%22
  // const signupPreset = { "Application": getEnvVar.APP_NAME.toUpperCase(), "Plan": getEnvVar.EMERT_INVITE_PLAN, "InviteCode": getEnvVar.EMERT_INVITE_OFFER };
  // const signupPresetStr = JSON.stringify(signupPreset);
  // const signupUrl = `${getEnvVar.signupPartUrl}&preset="${signupPresetStr.replace(/"/g, '\\"')}"`;
  // preset : { Application: , Plan: , InviteCode: }
  // To do the autofill, the form must set the field name to parameter value. IE. Application
  // in the Form Builder, edit the form > Click on the field > edit the property values > set the 'Property name' to 'Application' (default is the item's seqno)
  if (urlParams.preset) locationData.preset = JSON.parse(JSON.parse(urlParams.preset));

  try {
    // account/form used to fetch forms when lnch is running
    // Otherwise you'll get error:
    // Selection:setupUserFormsSelection() - Launch form not found : NaN
    if (urlParams.account) locationData.accountNo = Number(urlParams.account);
    if (urlParams.form) locationData.formNo = Number(urlParams.form);
    //if (urlParams.signin) locationData.signin = urlParams.signin;
  } catch {
    locationData.accountNo = Number(-1);
    locationData.formNo = Number(-1);
    //locationData.signin = 'no';
  }
  //console.log('createEnvVar:updateLocationData() - dump locationData : ');
  //console.log(locationData);
};


//
// LocationExtractor : Binds to the 'window.onpopstate'
//
// An Auth web app can be invoked as    : http://localhost:8081/?target=https://localhost:8082
// A non-Auth web app can be invoked as : http://localhost:8082/?jwt=xxxx
// and the locationExtractor grabs the query parameter from location and
// updates the StateTree's 'jwt' or 'target' properties
//
//const locationExtractor = new location.utils.LocationExtractor(window,  model.updateLocation);
// Simulate firing window.onpopstate event (Firefox & Chrome does not support)

function locationExtractor() {

  if (typeof window !== 'undefined') {
    //console.info('createEnvVar:locationExtractor()- >>>> location extractor invoked on loc: ', window.location || 'non window env.');
    var match,
      pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) {
	return decodeURIComponent(s.replace(pl, ' '));
      },
      query = (window && window.location && window.location.search.substring(1)) || '';

    let urlParams = {};
    while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);

    //console.info('createEnvVar:locationExtractor()- >>>> urlParams: ', urlParams);

    // urlParams: { protocol, hostname, ext, }
    urlParams.protocol = window.location.protocol;
    urlParams.hostname = window.location.hostname;
    urlParams.pathname = window.location.pathname;
    const ext = domainExtractor(window.location.hostname);
    urlParams = Object.assign(urlParams, ext);

    //console.info('createEnvVar:locationExtractor()- >>>> urlParams after object assign: ', urlParams);

    updateLocationData(urlParams);

    //console.info('createEnvVar:locationExtractor()- >>>> location extractor after context update ' + JSON.stringify(urlParams));

    return locationData;   //urlParams;
  }
}



if (typeof window !== 'undefined') {
  window.onpopstate = locationExtractor;
}

/* THIS DOES NOT WORK - Does not like the assert option
let keyValEnvVar = (async () => {
  let module = await import(/ * @vite-ignore * / './json/projEnvVar.json', { assert: { type: "json" } });
  const json = module.default;
  return json;
})();
*/

const _setup = () => {
  //console.log('envVarMgr: Do the initial setup');

  //let module = await import(/* @vite-ignore */ './json/projEnvVar.json', { assert: { type: "json" } });
  //const keyValEnvVar = await module.default;
  /*
  THIS DOES NOT WORK - in the end, envMap is set to a promise. And then ContentBody fails to access an env. var. as it's reading off of a promise.
  let keyValEnvVar = await (async () => {
    let module = await import(/ * @vite-ignore * / './json/projEnvVar.json');
    const json = await module.default;
    return json;
  })();
  */

  //let jsonFile = await import(filePath, { assert: { type: "json" } });   DOES NOT WORK
  //console.log('envVarMgr: dump dynamically imported json file ', keyValEnvVar);
  keyValEnvVar['locationData'] = locationExtractor();
  //console.log('envVarMgr: _setup() dump keyValEnvVar.json', keyValEnvVar);
  envMap = keyValEnvVar;
  isSetup = true;
}
const getEnvVal = (key) => {
  //console.log('envVarMgr:getEnvVal() - req for key: ' + key);
  if (!isSetup) _setup();
  return envMap && envMap[key];
}

const getEnvMap = () => {
  if (!isSetup) _setup();
  //console.log('envVarMgr:getEnvMap() - req for map', JSON.stringify(envMap));
  return envMap;
}

export { getEnvVal, getEnvMap };
