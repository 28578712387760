import React, { useReducer } from 'react';

import { WebStorageStateStore } from "oidc-client-ts";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { LayoutLocal } from './LayoutLocal';

import { getEnvVal } from '#src';
import {
  ContentLayout, 
  createInitState,
  appReducer,
  Header,
} from 'clnt-common';

import {
  themes,
  getEnvMap,
  getDisplayLocalStore,
  updateDisplayLocalStore,
} from '#src';

import { siteData } from './data/siteData';

// following imports can be removed when eliminating ContentBody  (case #2)
// following imports required for case #1 - original mode
import { RouterProvider } from 'react-router-dom'
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import { AuthProvider } from "react-oidc-context";
import { AppRoutes } from './AppRoutes';
import { ContentBody } from '#src';

// NOTE: global.css is included in index.html as <link>

//
// Authentik supported API URLs:
// 
//https://auth-kripa-com.docker.localhost/application/o/tmpl/.well-known/openid-configuration  - OpenID Configuration URL
//https://auth-kripa-com.docker.localhost/application/o/tmpl/                  - OpenID Configuration Issuer
//https://auth-kripa-com.docker.localhost/application/o/authorize/             - Authorize URL
//https://auth-kripa-com.docker.localhost/application/o/token/                 - Token URL
//https://auth-kripa-com.docker.localhost/application/o/userinfo/              - Userinfo URL
//https://auth-kripa-com.docker.localhost/application/o/tmpl/end-session/      - Logout URL
//https://auth-kripa-com.docker.localhost/application/o/tmpl/jwks/             - JWKS URL

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

function reducer(state, action) {
  switch (action.type) {
    case 'event':
      {
        const id = getRandomInt(9999999999999).toString();
        return [{ ...action.data, id, date: Date.now() }, ...state];
      }
    default:
      throw new Error();
  }
}

function App(props) {
  const [events, dispatch] = useReducer(reducer, []);

  const { type, appContext, routes, route } = props
  const getEnvVar = getEnvMap();

  const redirectPath = getEnvVal('AUTH_SVC_REDIRECT_PATH') || '/myaccount';
  const silentRedirectPath = getEnvVal('AUTH_SVC_SILENT_REDIRECT_PATH') || '/myaccount_silent';

  // 'kripa-com.docker.localhost'  OR   "http://localhost:3001"
  const authHost = getEnvVal('AUTH_SVC_URL') || 'https://auth-url-unknown';

  const configPath = getEnvVal('AUTH_SVC_CONFIG_PATH') || '/application/o';
  const authPath = configPath + '/' + getEnvVal('APP_CODE').toLowerCase() || 'APP';   // '/application/o/nulc'

  const clientId = getEnvVal('APP_AUTH_CLIENT_ID') || 'APP-AUTH-CLIENT-ID-UNKNOWN';
  const scopes = getEnvVal('APP_AUTH_SCOPES') || 'openid profile email access'; // 'openid profile email api offline_access',
  const refreshTime = getEnvVal('AUTH_SVC_TOKEN_REFRESH_TIME') || 60;
  
  const myHost = typeof window !== 'undefined' ? window.location.origin : 'unknown'; //'http://localhost:3000'
  const redirectUri = myHost + redirectPath;
  const silentRedirectUri = myHost + silentRedirectPath;
  const authority = authHost + authPath;

  const oidcConfig = {
    client_id: clientId,                                   // 'interactive.public.short'
    //client_secret: '',             // need for react-oidc-context
    //response_type: "code",         // need for react-oidc-context
    //filterProtocolClaims: true,    // need for react-oidc-context
    //loadUserInfo: true,            // need for react-oidc-context
    //metadata: {                    // need for react-oidc-context
    //  authorization_endpoint: `http://localhost:3001/oauth/authorize`,
    //  token_endpoint: `http://localhost:3001/oauth/token`
    //},
    redirect_uri: redirectUri,                             // window.location.origin + '/authentication/callback'
    silent_redirect_uri: silentRedirectUri,                // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
    scope: scopes,
    authority: authority,                                  // 'https://demo.duendesoftware.com'
    service_worker_relative_url:'/OidcServiceWorker.js',
    service_worker_only:true,                              // true | false 
    refresh_time_before_tokens_expiration_in_second: refreshTime,
    //logout_tokens_to_invalidate: ['access_token', 'refresh_token'], // default - Array<string>, // Optional tokens to invalidate during logout, default: ['access_token', 'refresh_token']
    // authority_time_cache_wellknowurl_in_second: 60* 60,
    // storage: localStorage,
    // silent_login_timeout: 3333000
    // monitor_session: true,
    // token_renew_mode: TokenRenewMode.access_token_invalid,
    // token_automatic_renew_mode: TokenAutomaticRenewMode.AutomaticOnlyWhenFetchExecuted,
    // demonstrating_proof_of_possession: false,
    //authority_configuration: {
    //authorization_endpoint: String,
    //token_endpoint: String,
    //userinfo_endpoint: String,
    //end_session_endpoint: String,
    //revocation_endpoint: authHost + '/application/o/tmpl/end-session/',
    //check_session_iframe
    //}
  };

  //Log.setLogger(console);
  //Log.setLevel(Log.DEBUG);

  //console.log('App: oidc config: ', oidcConfig);
  
  const oidcOnEvent = (configurationName, eventName, data) => {
    // oidc:default:token_timer {timeLeft: 42669}
    // oidc:default:silentLoginAsync_error {reason: 'timeout'}
    // oidc:default:refreshTokensAsync_silent_error
    // oidc:default:tryKeepExistingSessionAsync_begin
    // oidc:default:tryKeepExistingSessionAsync_end

    if (eventName !== 'token_timer') {
      //console.log(`App:oidcOnEvent() - oidc:${configurationName}:${eventName}`, data);
      dispatch({ type: 'event', data: { name: `oidc:${configurationName}:${eventName}`, data } });
    }

  };

  oidcConfig['onSigninCallback'] = (user) => {
    //console.log('App:onSigninCallback() - invoked');
    window.history.replaceState(
      {},
      document.title,
      '/myaccount'   //window.location.pathname
    )
  }

  //console.log('App: ----------- render AuthProvider with ContentBody and BrowserRouter');
  //const vitePageContext = appContext.metaData;
  const appInitState = createInitState('RECL', getEnvVar, appContext,
    getDisplayLocalStore, updateDisplayLocalStore );


  //console.log('App: page meta data ', vitePageContext);
  
  if (type === 'client') {
    oidcConfig['userStore'] = new WebStorageStateStore({
      store: typeof window !== 'undefined' ? window.localStorage : null
    });
  }

  // Get list of objects describing pages displaying Ads
  const adSpecs = siteData({type:'routesAdSpecs'});

  // props avail from parent: type, appContext, routes, route

  // This is run ONE TIME and reused in browser for all types of pages: tsx, blog, mdx
  // implies the 1st page load, if it's for tsx then all subsequent page transition will
  // use tsx provider.
  const Layout = () => {
    return (
      <ContentLayout
	themes={themes}
	appInitState={appInitState}
	appReducer={appReducer}
	headerOptions={siteData({type: 'headerOptions' })}
	footerOptions={siteData({type: 'footerOptions' })}
	oidcConfig={oidcConfig}
	adSpecs={adSpecs} 
	getEnvVar={getEnvVar} 
      >
	<div data-where="App-Outlet">
	<Outlet />
	</div>
      </ContentLayout>
    );
  };

  // ??? need to check if node version has the same content in routes (ie. path and element)
  //console.log('Dump routes', routes);

  const rts = [];
  for (let i = 0; i < routes.length; i++) {
    const r = routes[i];
    //console.log('Dump route', r);

    const path = r.path.substr(1);
    //const importstr = r.import;
    const element = r.element;

    if (r.path === '/') {
      rts.push(<Route key={'index'} index element={element} />);
    }
    else
    if (r.path === '*') {
      //console.log('processing route path ' + path);
      rts.push(<Route key={'route-' + i} path={'*'} element={element}></Route>);
    }
    else {
      //console.log('processing route path ' + path);
      rts.push(<Route key={'route-' + i} path={path} element={element}></Route>);
    }
  }	

  //console.log('Dump routes rts:', rts);

  if (type === 'client') {
    return (
      <BrowserRouter location={route}>
	<Routes>
	  <Route path="/" element={<Layout />}>
	    { rts }
	  </Route>
	</Routes>
      </BrowserRouter>
    );
  }   
  else {   // 'server'
    return (
      <StaticRouter location={route}>
	<Routes>
	  <Route path="/" element={<Layout />}>
	    { rts }
	  </Route>
	</Routes>
      </StaticRouter>
    );
  } 

   /*
  console.log('App.tsx: Returning ContentLayout request for: ' + route)
  // case #3 BrowserRouter
  return (
    <ContentLayout
      themes={themes}
      appInitState={appInitState}
      appReducer={appReducer}
      headerOptions={siteData({type: 'headerOptions' })}
      footerOptions={siteData({type: 'footerOptions' })}
      vitePageContext={vitePageContext}
      Adverts={Adverts}
      oidcConfig={oidcConfig}
      routes={routes}
      currentRoute={route}
      routeType={'BrowserRouter'}
    >
      {RouterInstance }
    </ContentLayout>
  );   

  */
}

export default App
